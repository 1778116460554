export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AddBeat.Success': 'Adding success',
  'Server.DeleteBeat.Success': 'Deleting success',
  'Server.UpdateBeat.Success': 'Updating success',
  'Mes.Users.Add.Successfully': 'Add successful account',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully erase',
  'Mes.Users.UpdateSuccessfully': 'successfulAccountInformationUpdates',
  'Mes.Researchs.Update.Successfully': 'Updating successful research',
  'Mes.Researchs.Add.Successfully': 'Add a successful study',
  'Mes.Researchs.Delete.Successfully': 'Delete successful research',
  'server.data.success': 'Successful',
  Successful: 'Successful',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'The recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Successful password reset',
  'Mes.Profile.GetDetailSuccessfully': 'Get successful account information',
  'Mes.Profile.OldPassword.Wrong': 'The old password is not correct',
  'Mes.Profile.ChangePasswordSuccessfully': 'Successful password update',
  'Mes.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Role.CreateSuccessfully': 'Add a successful role ',
  'Mes.Role.DeleteSuccessfully': 'Delete the role of success',

  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username already exists',
  'Mes.User.Required.Username': 'Username is required',
  'Mes.User.NotWhiteSpace.Username': 'Username cannot contain whitespace',
  'Mes.User.NotSpecialCharacter.Username':
    'Username cannot contain special characters (except . @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Email already exists',
  'Mes.User.Required.Email': 'Email is required',
  'Mes.User.NotWhiteSpace.Email': 'Email cannot contain whitespace',
  'Mes.User.OverLength.Name': 'User name cannot exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid avatar picture',
  'Mes.User.Invalid.Password': 'Invalid password',
  'Mes.User.Required.Password': 'Password is required',
  'Mes.User.IsWrong.OldPassword': 'Incorrect old password',
  'Mes.User.Invalid.OldPassword': 'Invalid old password',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'Role ID is required',
  'Mes.User.Status.Required': 'Status is required',
  'Mes.User.Status.Invalid': 'Invalid status',
  'Mes.User.Required.RecoveryCode': 'Recovery code is required',
  'Mes.User.NotFound.RecoveryCode': 'Recovery code not found',
  'Mes.User.NotFound': 'User not found',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.User.Blocked': 'User is blocked',
  'Mes.UserRefreshToken.Required.Token': 'Refresh token is required',
  'Mes.UserRefreshToken.WasUsed': 'Refresh token has been used',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refresh token',
  'Mes.UserRefreshToken.Expired.Token': 'Refresh token has expired',
};
