import React from 'react';

import { IRouter } from '@routers/interface';

export const routerListApplication: IRouter = {
  path: '/application-iframe',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'listApplication.name', //translate here for breadcrumb and sidebar
  masterLayout: { hideFooter: true, hideHeader: true, hideSideBar: true },
};
